<template>
  <div class="company">
    <img src="../../assets/image/company_bg.png" class="bgimg" alt="" />
    <div class="company-box">
      <div class="logo" :class="{ 'logo-icon': !welcomeAvatarUrl }">
        <img :src="welcomeAvatarUrl" v-if="welcomeAvatarUrl" />
        <i class="iconfont icon7 icon-touxiang" v-else></i>
      </div>
      <p class="name">
        <span>{{ entryComp.aboutName }}</span>
      </p>
      <p class="info-text">
       <pre> {{ entryComp.aboutIntroduce }}</pre>
      </p>
      <div class="address">
        <!-- <p>北京市朝阳区建国路56号天洋运河壹号F1栋</p> -->
        <a :href="initHref(entryComp.aboutPropagandaUrl)">{{entryComp.aboutPropagandaUrl}}</a>
        <!-- <p>{{ entryComp.aboutPropagandaUrl }}</p> -->
      </div>
    </div>
  </div>
</template>
<script>
import untis from "../../utils";
export default {
  data() {
    return {
      welcomeAvatarUrl: "",
      entryComp: {
        // 企业介绍
        aboutName: "",
        aboutIntroduce: "",
        aboutPropagandaUrl: "",
      },
    };
  },

  created() {
    this.getInit();
  },
  mounted() {},
  methods: {
    initHref(src){
      //解决不携带htpp或者https只在当前域名下跳转
      return src.indexOf('http') > -1 ? src : `https://${src}`
    },
    getInit() {
      let con = JSON.parse(untis.getSessionStorage("hello"));
      if (con.welcomeAvatarUrl) {
        this.welcomeAvatarUrl = untis.getImgUrl() + con.welcomeAvatarUrl; // 头像
      }

      this.entryComp = JSON.parse(untis.getSessionStorage("company"));
    },
  },
};
</script>
<style lang="scss" scoped>
.company {
  min-height: 100vh;
  padding-top: 50px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  position: relative;
  background: #2e98ff;
  overflow: hidden;
  .bgimg {
    width: 100%;
    height: 100%;
    display: block;
  }
  .company-box {
    width: 100%;
    position: absolute;
    top: 50px;
    left: 0;
    color: #fff;
    .logo-icon {
      background: #f5f5f5;
      line-height: 72px;
    }
    .logo {
      width: 72px;
      height: 72px;
      margin: 40px auto 27px auto;
      border: 3px solid #ffffff;
      border-radius: 50%;
      overflow: hidden;
      .icon7 {
        font-size: 48px;
        color: #bfbfbf;
      }
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .name {
      text-align: center;
      margin-bottom: 35px;
      span {
        display: inline-block;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 21px;
        padding: 5px 22px;
        background: #ffc514;
        border-radius: 15px;
      }
    }
    .info-text {
      width: 325px;
      // max-height: 270px;
      margin: 0 auto;
      text-align: left;
      // text-indent: 2em;
      font-size: 13px;
      line-height: 22px;
      pre {
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
      }
      // overflow-y: scroll;
    }
    .address {
      word-wrap: break-word;
      padding: 0 30px;
      margin-top: 88px;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      a {
        color: #ffc514;
        text-decoration: underline;
      }
      p {
        margin-bottom: 5px;
        padding: 0 10px;
        word-break: break-all;
      }
    }
  }
}
</style>